import React from 'react'
import { css } from '@emotion/react'
import { LayoutIR } from '../../../components/organisms/Layout/LayoutIR'
import { HeadingTertiary } from '../../../components/atoms/Heading/HeadingTertiary'
import { useLoadRemoteScript } from '../../../components/hooks/useLoadRemoteScript'
import { graph } from '../../../components/styles/graph'
import { SEOIR } from '../../../components/data/SEO'
import { ENGLISH_TITLE } from '../../../components/data/English'

const cashflow = css`
  @media screen and (min-width: 1200px) {
    .bg-white {
      width: calc(50% - 20px);
    }
  }
`

const Page = () => {
  // 表の埋め込みに使用。id属性とsrcを指定
  useLoadRemoteScript(
    '#ca01',
    '//www.xj-storage.jp/public-graph-at/table/AS04576/35536612/11ba/4cc9/a6ed/f079ac8e4cd7/table_ja.js'
  )
  useLoadRemoteScript(
    '#ca02',
    '//www.xj-storage.jp/public-graph-at/table/AS04576/b685781d/8c9a/4c68/8b5d/0bb071947c7e/table_ja.js'
  )
  useLoadRemoteScript(
    '#ca03',
    '//www.xj-storage.jp/public-graph-at/table/AS04576/2ee24123/43e9/43d9/aa60/67bf1bde0ab6/table_ja.js'
  )
  useLoadRemoteScript(
    '#ca04',
    '//www.xj-storage.jp/public-graph-at/table/AS04576/3c89d60d/5d6b/4ad0/aeaf/44c1dd7f9fca/table_ja.js'
  )
  return (
    <LayoutIR
      heading="キャッシュフローの状況"
      headingEnglish={ENGLISH_TITLE.CashFlow}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: '業績・財務ハイライト', url: '/ir/performance' },
        { title: 'キャッシュフローの状況', url: '/' },
      ]}
    >
      <div className="pc:flex pc:flex-wrap" css={cashflow}>
        <div className="pc:pt-12 pc:px-6 pc:mr-10 pc:pb-6 mb-14 pc:mb-10 bg-white">
          <HeadingTertiary label="営業活動によるキャッシュ・フロー" />
          <div css={graph}>
            <img
              src="//www.xj-storage.jp/public-graph-at/graph/AS04576/35536612/11ba/4cc9/a6ed/f079ac8e4cd7/graph_ja.png"
              alt="営業活動によるキャッシュ・フロー"
            />
            <div id="ca01" className="mb-2 overflow-x-auto"></div>
            <p className="text-right text-xs pt-2">（百万円）</p>
          </div>
        </div>

        <div className="pc:pt-12 pc:px-6 pc:pb-6 mb-14 pc:mb-10 bg-white">
          <HeadingTertiary label="投資活動によるキャッシュ・フロー" />
          <div css={graph}>
            <img
              src="//www.xj-storage.jp/public-graph-at/graph/AS04576/b685781d/8c9a/4c68/8b5d/0bb071947c7e/graph_ja.png"
              alt="投資活動によるキャッシュ・フロー"
            />
            <div id="ca02" className="mb-2 overflow-x-auto"></div>
            <p className="text-right text-xs pt-2">（百万円）</p>
          </div>
        </div>

        <div className="pc:pt-12 pc:px-6 pc:mr-10 pc:pb-6 mb-14 pc:mb-4 bg-white">
          <HeadingTertiary label="財務活動によるキャッシュ・フロー" />
          <div css={graph}>
            <img
              src="//www.xj-storage.jp/public-graph-at/graph/AS04576/2ee24123/43e9/43d9/aa60/67bf1bde0ab6/graph_ja.png"
              alt="財務活動によるキャッシュ・フロー"
            />
            <div id="ca03" className="mb-2 overflow-x-auto"></div>
            <p className="text-right text-xs pt-2">（百万円）</p>
          </div>
        </div>

        <div className="pc:pt-12 pc:px-6 pc:pb-6 mb-14 pc:mb-4 bg-white">
          <HeadingTertiary label="現金及び現金同等物の期末残高" />
          <div css={graph}>
            <img
              src="//www.xj-storage.jp/public-graph-at/graph/AS04576/3c89d60d/5d6b/4ad0/aeaf/44c1dd7f9fca/graph_ja.png"
              alt="現金及び現金同等物の期末残高"
            />
            <div id="ca04" className="mb-2 overflow-x-auto"></div>
            <p className="text-right text-xs pt-2">（百万円）</p>
          </div>
        </div>
        <p className="text-sm leading-normal">
          ご利用上の注意
          <br />
          ・決算短信等の訂正が発表された場合、本データの記載内容は即時反映されません。
          <br />
          ・一部の指標については、決算短信様式変更に伴い通期のみの更新となる場合があります。
        </p>
      </div>
    </LayoutIR>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => (
  <SEOIR title="キャッシュフローの状況" url="/ir/performance/cashflow" />
)
